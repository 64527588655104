<template>
  <div class="about">
    <van-tabs v-model="activeTab" @change="onTabChange">
      <van-tab title="第一周" @click="fetchData('oneweek')">
        <div class="about-container">
          <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
          </div>
          <div class="app-container">
            <div class="container-top">
              <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="items[1].avatar" alt="">
                  <p>{{items[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{items[1].count}}张</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="items[0].avatar" alt="">
                  <p>{{items[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{items[0].count}}张</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="items[2].avatar" alt="">
                  <p>{{items[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{items[2].count}}张</span>
              </li>
            </ul>
            </div>
            <div class="container-center">
              <ul>
                <li>排名</li>
                <li>头像</li>
                <li>主播名称</li>
                <li>瓜分卡(张)</li>
              </ul>
            </div>
            <div class="container-bottom">
              <ul>
              <li v-for="(item,index) in items.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="第二周" @click="fetchData('twoweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages[1].avatar" alt="">
                  <p>{{messages[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages[1].count}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages[0].avatar" alt="">
                  <p>{{messages[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages[0].count}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages[2].avatar" alt="">
                  <p>{{messages[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages[2].count}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第三周" @click="fetchData('threeweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages1[1].avatar" alt="">
                  <p>{{messages1[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages1[1].count}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages1[0].avatar" alt="">
                  <p>{{messages1[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages1[0].count}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages1[2].avatar" alt="">
                  <p>{{messages1[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages1[2].count}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages1.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第四周" @click="fetchData('fourweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages2[1].avatar" alt="">
                  <p>{{messages2[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages2[1].count}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages2[0].avatar" alt="">
                  <p>{{messages2[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages2[0].count}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages2[2].avatar" alt="">
                  <p>{{messages2[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages2[2].count}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages2.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.total }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第五周" @click="fetchData('fiveweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages3[1].avatar" alt="">
                  <p>{{messages3[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages3[1].count}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages3[0].avatar" alt="">
                  <p>{{messages3[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages3[0].count}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages3[2].avatar" alt="">
                  <p>{{messages3[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages3[2].count}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages3.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第六周" @click="fetchData('sixweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="messages4[1].avatar" alt="">
                  <p>{{messages4[1].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages4[1].count}}</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="messages4[0].avatar" alt="">
                  <p>{{messages4[0].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages4[0].count}}</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="messages4[2].avatar" alt="">
                  <p>{{messages4[2].user_nicename}}</p>
                </div>
                <span>瓜分卡：{{messages4[2].count}}</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in messages4.slice(3)" :key="item.ranking" >
                <span>{{index + 4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <!-- <van-tab title="第七周" @click="fetchData('sevenweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages5" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第八周" @click="fetchData('eightweek')">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="" alt="">
                  <p>昵称</p>
                </div>
                <span>瓜分卡</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages6" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
        
      </van-tab> -->
    </van-tabs>
  </div>
</template>
<script>


export default {
  name: 'PageHome',
  data() {
    return {
      // 第一周
      items: [ // 定义一个数组
      { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
      // 第二周
      messages :[
      { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
      // 第三周
      messages1 :[
      { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
      // 第四周
      messages2 :[
      { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
      //第五周
      messages3 :[
      { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
      //第六周
      messages4 :[
            { id: 1,avatar:'', touid: '116781', user_nicename: '', total: "" },
            { id: 2,avatar:'', touid: '106417', user_nicename: '', total: "" },
            { id: 3,avatar:'', touid: '109439', user_nicename: '', total: "" },
            { id: 4,avatar:'', touid: '164847', user_nicename: '', total: "" },
            { id: 5,avatar:'', touid: '171887', user_nicename: '', total: "" },
            { id: 6,avatar:'', touid: '117431', user_nicename: '', total: "" },
            { id: 7,avatar:'', touid: '108263', user_nicename: '', total: "" },
            { id: 8,avatar:'', touid: '107047', user_nicename: '', total: "" },
            { id: 9,avatar:'', touid: '163540', user_nicename: '', total: "" },
            { id: 10,avatar:'', touid: '221601', user_nicename: '', total: "" },
      ],
    }
  },
  created() {
    this.fetchData('oneweek');
  },
  methods: {
    onTabChange(newTabIndex) {
      // 根据选中的标签索引确定请求的接口
      if (newTabIndex === 0) {
        this.fetchData('onewwk');
      } else if (newTabIndex === 1) {
        this.fetchData('twoWeek');
      } else if (newTabIndex === 2) {
        this.fetchData('threeweek');
      } else if (newTabIndex === 3) {
        this.fetchData('fourweek');
      } else if (newTabIndex === 4) {
        this.fetchData('fiveweek');
      } else if (newTabIndex === 5) {
        this.fetchData('sixweek');
      } else if (newTabIndex === 6) {
        this.fetchData('sevenweek');
      }
    },
    fetchData(tabType) {
      let apiUrl;
      let startOfWeek;
      let endOfWeek;
      if(tabType =="oneweek"){
        startOfWeek ="1731254400";
        endOfWeek ="1731859199";
        apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
        fetch(apiUrl)
        .then((response) => {
          if (!response.ok) throw new Error('网络响应不是 OK');
          return response.json();
        })
        .then((data) => {
          this.items = data.data.rankInviteList;
        })
        .catch((error) => {
          console.error('发生错误:', error);
        });

      } else if(tabType =="twoweek"){

          startOfWeek ="1731859200";
          endOfWeek ="1732463999";
          apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
          fetch(apiUrl)
          .then((response) => {
            if (!response.ok) throw new Error('网络响应不是 OK');
            return response.json();
          })
          .then((data) => {
            this.messages = data.data.rankInviteList;
          })
          .catch((error) => {
            console.error('发生错误:', error);
          });
      } else if(tabType =="threeweek"){

        startOfWeek ="1732464000";
        endOfWeek ="1733068799";
        apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
        fetch(apiUrl)
        .then((response) => {
          if (!response.ok) throw new Error('网络响应不是 OK');
          return response.json();
        })
        .then((data) => {
          this.messages1 = data.data.rankInviteList;
        })
        .catch((error) => {
          console.error('发生错误:', error);
        });
      }  else if(tabType =="fourweek"){

          startOfWeek ="1733068800";
          endOfWeek ="1733673599";
          apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
          fetch(apiUrl)
          .then((response) => {
            if (!response.ok) throw new Error('网络响应不是 OK');
            return response.json();
          })
          .then((data) => {
            this.messages2 = data.data.rankInviteList;
          })
          .catch((error) => {
            console.error('发生错误:', error);
          });
      } else if(tabType =="fiveweek"){

        startOfWeek ="1733673600";
        endOfWeek ="1734278399";
        apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
        fetch(apiUrl)
        .then((response) => {
          if (!response.ok) throw new Error('网络响应不是 OK');
          return response.json();
        })
        .then((data) => {
          this.messages3 = data.data.rankInviteList;
        })
        .catch((error) => {
          console.error('发生错误:', error);
        });
        } else if(tabType =="sixweek"){

          startOfWeek ="1734278400";
          endOfWeek ="1734883199";
          apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
          fetch(apiUrl)
          .then((response) => {
            if (!response.ok) throw new Error('网络响应不是 OK');
            return response.json();
          })
          .then((data) => {
            this.messages4 = data.data.rankInviteList;
          })
          .catch((error) => {
            console.error('发生错误:', error);
          });
          } 
      // switch (tabType) {
      //   case 'yesterday':
      //     apiUrl = '/appapi/recording/list_zhou?type=total';
      //     break;
      //   case 'lastWeek':
      //     apiUrl = '/appapi/recording/list_zhou?type=week';
      //     break;
      //   case 'month':
      //     apiUrl = '/appapi/recording/list_zhou?type=month';
      //     break;
      // }

      // fetch(apiUrl)
      //   .then((response) => {
      //     if (!response.ok) throw new Error('网络响应不是 OK');
      //     return response.json();
      //   })
      //   .then((data) => {
      //     const items = data.data;
      //     this.rankingTop = items.slice(0, 3);
      //     this.otherRanking = items.slice(3);
      //   })
      //   .catch((error) => {
      //     console.error('发生错误:', error);
      //   });
    },
    // fetchData() {
    //   fetch('https://api.example.com/items') // 替换为你的实际 API URL
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('网络响应不是 OK');
    //       }
    //       return response.json();
    //     })
    //     .then(data => {
    //       this.items = data; // 将获取到的新数据赋值给 items
    //     })
    //     .catch(error => {
    //       console.error('发生错误:', error);
    //     });
    // },
    back() {
      this.$router.push('/'); // 返回首页
    },
  }
}
</script>


<style lang="less" scoped>
.about {
  position: relative;
  margin-top: -200px;
  // font-size: 14px;
  // padding-top: 246px;
  // background: url('~@/assets/images/about-bg.jpg') no-repeat top left / 100%;
  // margin-bottom: 50px;
  /deep/ .van-tabs__line {
    top: 28px;
    padding-bottom: 0 !important;
    background-color: #fff;
  }
  /deep/ .van-tabs{
    &__wrap{
      z-index: 20;
      display: flex;
      align-items: center;
      .van-tab{
        font-size: 12px;
        color: #fff;
        background: linear-gradient(to bottom, #e06e53, #bc2212);
        padding: 15px;
      }
    }

  }
  /deep/ .van-tabs__nav--line {
    height: 32px;
    // margin-left: 10px;
    padding-bottom: 0 !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap{
    margin: 10px 0;
    border-radius: 10px;
  }
  /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius:20px;
  }
  .about-container {
    height: 260;
    background: url('~@/assets/images/bg.png') no-repeat top left / 100%;
  }
  .container-title{
    color: #fff;
    font-size: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .container-title img{
    width: 14px;
    height: 18px;
    margin-right: 5px;
  }
  .container-title span{
    line-height: 24px;
  }
  .tab-pic{
    width: 180px;
    // height: 36px;
    position: absolute;
    top: -20px;
    left: 80px;
  }
  .app-container{
    position: relative;
    width: 341px;
    margin: 4px auto;
    height: 534px;
    background: url('~@/assets/images/content1.png') no-repeat top left / 100%;
  }
  .container-top{
    ul{
      display: flex;
      justify-content: space-around;
      li{
        margin-top: 40px;
      }
      .ul-chidern1{
        position: relative;
        margin-top: 60px;
        width:106px;
        height:132px;
        background: url('~@/assets/images/top2.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern1-conent{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 22px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
          span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 52px;
          margin-left: 6px;
          color: #550e00;
          font-weight: bold;
        }
        .ul-chidern1-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/tab2.png')no-repeat top left / 100%;
            position: absolute;
            top: -2px;
            left: 19px;
          }
      }
      .ul-chidern2{
        position: relative;
        margin-top: 40px;
        width:106px;
        height:144px;
        background: url('~@/assets/images/top1.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern2-conent{
          width: 54px;
          height: 54px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 15px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 63px;
          margin-left: 6px;
          color: #550e00;
          font-weight: bold;
        }
        .ul-chidern2-conent::after{
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background: url('~@/assets/images/tab1.png')no-repeat top left / 100%;
            position: absolute;
            top: 1px;
            left: 22px;
          }
      }
      .ul-chidern3{
        margin-right: 8px;
        margin-top: 60px;
        width:105px;
        height:138px;
        background: url('~@/assets/images/top3.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern3-conent{
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 51px;
          margin-left: 6px;
          color: #550e00;
          font-weight: bold;
        }
        .ul-chidern3-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/tab3.png')no-repeat top left / 100%;
            position: absolute;
            top: -12px;
            left: -6px;
          }
      }
    }
    }
  .container-center{
    color: #b04400;
    font-weight: 600;
    margin-top:18px;
    font-size: 16px ;
    ul{
      margin-left: 10px;
      width: 320px;
      display: flex;
      justify-content: space-around;
      padding-bottom: 10px;
      border-bottom: 1px dashed #b04400;
      li{
        display: flex;
        justify-items: center;
        margin-left: -14px;
      }

    }
  }
  .container-bottom{
    ul{
      overflow-y: auto;
      margin-left: 8px;
      margin-top: 10px;
      width: 324px;
      height: 290px;
      li {
        font-size: 13px;
        padding: 2px 0;
        span:nth-child(1) {
          text-align: center;
          width: 26px;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          text-align: center;
          width: 90px;
          border-radius: 100%;
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
          }
        }
        span:nth-child(3) {
          text-align: center;
          display: inline-block;
          width: 120px;
          padding-right: 12px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
        span:nth-child(4) {
          width: 60px;
          display: inline-block;
          text-align: left;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
      }
      li:nth-child(even) {
        height: 32px;
        width: 100%; /* 偶数项背景色 */
        background-color: #f9dbbe;
      }
      li:nth-child(odd) {
        height: 34px;
        width: 100%;
 /* 偶数项背景色 */
      }
    }
  }
}
.back{
  position: absolute;
  top: -244px;
  left: 10px;
}
</style>